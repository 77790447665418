ul.pagination > li {
    @apply font-medium rounded-full w-9 h-9 flex items-center justify-center text-lightBlue cursor-pointer dark:text-gray-200;
}
ul.pagination > li:hover {
    @apply bg-lightBlue text-white dark:text-gray-200;
}
ul.pagination > li.dots {
    @apply cursor-default hover:bg-transparent text-lightBlue dark:text-gray-200;
}
ul.pagination > li.prev {
    @apply rounded w-auto pr-2 pl-0 bg-transparent text-lightBlue dark:text-gray-200;
}
ul.pagination > li.next {
    @apply rounded w-auto pl-2 pr-0 bg-transparent text-lightBlue dark:text-gray-200;
}
ul.pagination > .active {
    @apply bg-lightBlue text-white dark:text-gray-200 hover:bg-lightBlue hover:text-white
}