html {
    height: 100vh;
}
body{
    height: 100vh;
}

div#root{
    height: 100%;
}
input[type=text]:disabled {
    color: gray !important;
    -webkit-text-fill-color: gray !important;
}
.Mui-disabled {
    color: gray !important;
    -webkit-text-fill-color: gray !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
}
.autofill-dark:-webkit-autofill,
.autofill-dark:-webkit-autofill:hover,
.autofill-dark:-webkit-autofill:focus,
.autofill-dark:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #2D333B inset !important;
    -webkit-text-fill-color: rgb(255, 255, 255) !important;
}
input:-webkit-autofill {
    -webkit-text-fill-color: rgb(0, 0, 0) !important;
}
.bg-sidebar {
    background: linear-gradient(180deg, rgb(7, 0, 126) 0%, rgb(1, 12, 169)32%, rgb(0, 124, 207) 100%);
}
.bg-sidebar-dark {
    background: linear-gradient(180deg, rgb(7, 0, 126) 0%, rgb(1, 12, 169)32%, rgb(0, 6, 94) 100%);
}
.text-shadow {
    text-shadow: 1.5px 1.5px rgba(0,0,0,.75);
}
.MuiButton-root {
    text-transform: none !important;
}
.icon-shadow {
    -webkit-filter: drop-shadow( 1.5px 1.5px 1.5px rgba(0, 0, 0, .75));
    filter: drop-shadow( 1.5px 1.5px 1.5px rgba(0, 0, 0, .75));
    /* Similar syntax to box-shadow */
}
@font-face {
    font-family: 'ProximaNovaReg';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: local('ProximaNovaReg'), local('ProximaNovaReg'), url('./assets/fonts/Proxima-Nova-Regular.woff') format('woff');
    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}
.autofill-dark-label {
    color: red;
}